import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    updateResponse: null,
    error: null
};

const slice = createSlice({
    name: 'updateCandDetails',
    initialState,
    reducers: {
        setUpdateResponse(state, action) {
            state.updateResponse = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Async function to update candidate details
export function updateCandDetails(job_id, candidate_id, update_data) {
    return async () => {
        try {
            console.log("job_id333", update_data);
            // const response = await axios.post(
            //     `http://35.200.233.124:5015/updateCandidateDetails?job_id=${job_id}&candidate_id=${candidate_id}`,
            //     update_data, // Sending the remaining data in the body
            //     {
            //         headers: {
            //             Authorization: `Bearer ${authToken}` // Include the auth token in the headers
            //         }
            //     } // Sending the remaining data in the body
            // );
            const response = await axios.post(
                `http://35.200.233.124:5015/updateCandidateDetails?job_id=${job_id}&candidate_id=${candidate_id}`,
                update_data,
                {
                    headers: {
                        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkF5dXNoIiwiZXhwIjoxNzMyMTc4NTk5fQ.605t2TAedWjxVVZXTGEd8X5v8ItGlGTiiWc93YFhhl4'
                    }
                }
                );

            if (response && response.data) {
                dispatch(slice.actions.setUpdateResponse(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.setError(error.response?.data || error.message));
        }
    };
}
