// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    candidateDetails: null,
    candidateDetailsBkp: null,
    error: null
};

const slice = createSlice({
    name: 'getAllCandidateDetails',
    initialState,
    reducers: {
        // SET CANDIDATE DETAILS
        setCandidateDetails(state, action) {
            state.candidateDetails = action.payload;
        },
        setCandidateDetailsForFirstTime(state, action) {
            state.candidateDetails = action.payload;
            state.candidateDetailsBkp = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Async function to get candidate details
export function getAllCandidateDetails(job_id, candidate_id) {
    return async () => {
        try {
            // const response = await axios.get("/getAllCandidateDetails", {
            //     headers: {
            //         Authorization: `Bearer ${authToken}`
            //     },
            //     params: {
            //         job_id,
            //         candidate_id
            //     }
            // });
            const response = await axios.get("http://35.200.233.124:5015/getAllCandidateDetails", {
                params: {
                    job_id,
                    candidate_id

                },
                headers: {
                    //Authorization: `Bearer ${authToken}`
                    Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkF5dXNoIiwiZXhwIjoxNzMyMTc4NTk5fQ.605t2TAedWjxVVZXTGEd8X5v8ItGlGTiiWc93YFhhl4'

                }
            });
            if (response && response.data) {
                dispatch(slice.actions.setCandidateDetailsForFirstTime(response.data.candidate));
            }
        } catch (error) {
            dispatch(slice.actions.setError(error.response?.data || error.message));
        }
    }
}

// Function to manually set candidate details
export function setCandidateDetailData(responsePayload) {
    return async () => {
        try {
            console.log("responsePayload", JSON.stringify(responsePayload))
            dispatch(slice.actions.setCandidateDetails(responsePayload));
        } catch (error) {
            console.log('setCandidateDetails error', error);
        }
    };
}
