// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    usersS1: [],
    usersS2: [],
    followers: [],
    friendRequests: [],
    friends: [],
    gallery: [],
    posts: [],
    detailCards: [],
    simpleCards: [],
    profileCards: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USERS STYLE 1
        getUsersListStyle1Success(state, action) {
            state.usersS1 = action.payload;
        },

        // GET GALLERY
        getGallerySuccess(state, action) {
            state.gallery = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDetailCards() {
    return async () => {
        try {
            const response = await axios.get('/api/details-card/list');
            dispatch(slice.actions.getDetailCardsSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//----------------------------------------------------------------------

export function getGallery() {
    return async () => {
        try {
            const response = await axios.get('/api/gallery/list');
            dispatch(slice.actions.getGallerySuccess(response.data.gallery));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}