// import { createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { dispatch } from '../index';

// // Initial state
// const initialState = {
//     // statuses: [],
//     // error: null,
//     statuses: {
//         sourcing: [],
//         assessment: [],
//         offered: []
//     },
//     error: null
// };

// // Create slice
// const candidateStatusSlice = createSlice({
//     name: 'candidateStatus',
//     initialState,
//     reducers: {
//         setStatuses(state, action) {
//             state.statuses = action.payload;
//         },
//         setError(state, action) {
//             state.error = action.payload;
//         },
//     },
// });

// // Reducer
// export default candidateStatusSlice.reducer;

// // Export actions
// export const { setStatuses, setError } = candidateStatusSlice.actions;

// // Thunk for fetching candidate statuses
// export function fetchCandidateStatuses() {
//     return async () => {
//         try {
//             const response = await axios.get('http://35.200.233.124:5015/getCandidateJobStatusMasterList');

//             if (response && response.data && response.status === 200) {
//                 dispatch(setStatuses(response.data));
//             } else {
//                 dispatch(setError('Failed to fetch candidate statuses'));
//             }
//         } catch (error) {
//             console.error("fetchCandidateStatuses error", error);
//             dispatch(setError('An error occurred while fetching candidate statuses'));
//         }
//     };
// }

// import { createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { dispatch } from '../index';

// // Initial state
// const initialState = {
//     statuses: {
//         sourcing: [],
//         assessment: [],
//         offered: []
//     },
//     error: null
// };

// // Create slice
// const candidateStatusSlice = createSlice({
//     name: 'candidateStatus',
//     initialState,
//     reducers: {
//         setStatuses(state, action) {
//             const groupedStatuses = action.payload.reduce((acc, status) => {
//                 switch (status.stage) {
//                     case 'Sourcing':
//                         acc.sourcing.push(status);
//                         break;
//                     case 'Assessment':
//                         acc.assessment.push(status);
//                         break;
//                     case 'Offered':
//                         acc.offered.push(status);
//                         break;
//                     default:
//                         break;
//                 }
//                 return acc;
//             }, { sourcing: [], assessment: [], offered: [] });

//             state.statuses = groupedStatuses;
//         },
//         setError(state, action) {
//             state.error = action.payload;
//         },
//     },
// });

// // Reducer
// export default candidateStatusSlice.reducer;

// // Export actions
// export const { setStatuses, setError } = candidateStatusSlice.actions;

// // Thunk for fetching candidate statuses
// export function fetchCandidateStatuses() {
//     return async () => {
//         try {
//             const response = await axios.get('http://35.200.233.124:5015/getCandidateJobStatusMasterList');

//             if (response && response.data && response.status === 200) {
//                 dispatch(setStatuses(response.data.status)); // Make sure to pass the correct data
//             } else {
//                 dispatch(setError('Failed to fetch candidate statuses'));
//             }
//         } catch (error) {
//             console.error("fetchCandidateStatuses error", error);
//             dispatch(setError('An error occurred while fetching candidate statuses'));
//         }
//     };
// }

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';

// Initial state
const initialState = {
    statuses: {
        sourcing: [],
        assessment: [],
        offered: []
    },
    error: null
};

// Create slice
const candidateStatusSlice = createSlice({
    name: 'candidateStatus',
    initialState,
    reducers: {
        setStatuses(state, action) {
            const groupedStatuses = action.payload.reduce(
                (acc, status) => {
                    const normalizedStatus = {
                        order: status.Order,
                        stage: status.Stage,
                        status: status.Status,
                        status_id: status["Status ID"]
                    };

                    switch (normalizedStatus.stage) {
                        case 'Sourcing':
                            acc.sourcing.push(normalizedStatus);
                            break;
                        case 'Assessment':
                            acc.assessment.push(normalizedStatus);
                            break;
                        case 'Offered':
                            acc.offered.push(normalizedStatus);
                            break;
                        default:
                            break;
                    }
                    return acc;
                },
                { sourcing: [], assessment: [], offered: [] }
            );

            state.statuses = groupedStatuses;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Reducer
export default candidateStatusSlice.reducer;

// Export actions
export const { setStatuses, setError } = candidateStatusSlice.actions;

// Thunk for fetching candidate statuses
export function fetchCandidateStatuses() {
    return async () => {
        try {
            //const response = await axios.get('http://35.200.233.124:5015/getCandidateJobStatusMasterList');
            //const response = await axios.get('/getCandidateJobStatusMasterList');
            const response = await axios.get('http://35.200.233.124:5015/getCandidateJobStatusMasterList', {
                headers: {
                    //Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkF5dXNoIiwiZXhwIjoxNzI5MzIyMjc0fQ.daub7-4-GZAzcb-dMChSVE5uTISe43yQrhNUuzNnrnk`
                    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkF5dXNoIiwiZXhwIjoxNzMyMTc4NTk5fQ.605t2TAedWjxVVZXTGEd8X5v8ItGlGTiiWc93YFhhl4`
                }
            });
            console.log('API Response:', response.data);
            if (response && response.data && response.status === 200) {
                dispatch(setStatuses(response.data.statuses));
            } else {
                dispatch(setError('Failed to fetch candidate statuses'));
            }
        } catch (error) {
            console.error('fetchCandidateStatuses error', error);
            dispatch(setError('An error occurred while fetching candidate statuses'));
        }
    };
}
