import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';
import demoEngagementFlowSteps from './getEngagementFlowSteps.json';

// Initial state
const initialState = {
    flowDetails: null,
    steps: [],
    error: null
};

// Create slice
const slice = createSlice({
    name: 'engagementFlowSteps',
    initialState,
    reducers: {
        setFlowDetails(state, action) {
            state.flowDetails = action.payload;
        },
        setSteps(state, action) {
            state.steps = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Export actions
export const { 
    setFlowDetails,
    setSteps,
    setError } = slice.actions;

// Thunks
export function getEngagementFlowSteps(flow_id) {
    return async () => {
        try {
            const response = await axios.get('http://35.200.233.124:5015/getEngagementFlowSteps', {
                params: { flow_id },
                headers: {
                    //Authorization: `Bearer ${authToken}`
                    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkF5dXNoIiwiZXhwIjoxNzI5MzIyMjc0fQ.daub7-4-GZAzcb-dMChSVE5uTISe43yQrhNUuzNnrnk`
                }
            });
            //const response ={data:demoEngagementFlowSteps, status:200};

            if (response && response.data && response.status === 200) {
                dispatch(slice.actions.setFlowDetails({
                    flow_description: response.data.flow_description,
                    flow_id: response.data.flow_id,
                    flow_name: response.data.flow_name
                    
                }));
                dispatch(slice.actions.setSteps(response.data.steps));
            } else {
                dispatch(slice.actions.setError('Failed to fetch flow steps'));
            }
        } catch (error) {
            console.log("getEngagementFlowSteps error", error);
            dispatch(slice.actions.setError('An error occurred while fetching flow steps'));
        }
    };
}


