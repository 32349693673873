// third-party
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    message: null,
    error: null
};

const slice = createSlice({
    name: 'setCandidateStatus',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.message = null;
            state.error = null;
        },
        setStatus(state, action) {
            state.loading = false;
            state.message = action.payload;
        },
        startError(state, action) {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Export actions
export const { startLoading, setStatus, startError } = slice.actions;

// Thunks
export function setCandidateStatus(job_id, candidate_id, status_id, user_id) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());

            const formData = new FormData();
            
            formData.append('job_id', job_id);
            formData.append('cand_id', candidate_id);
            formData.append('status_id', status_id);
            formData.append('user_id', user_id);            

            const response = await axios.post(
                'http://35.200.233.124:5015/setCandidateJobStatus', 
                formData,
                {
                    headers: {
                        Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InNhbmNoaXRtZSIsImV4cCI6MTczMDIyMjY0Mn0.aAhiznsr6iZTbFiiyog9wuogzMIK3t9uXy_jkYAn9eg'

                    }
                }
            );
            
            // const response = await axios.post('http://35.200.233.124:5015/setCandidateJobStatus',
            //     {   job_id:'81181144',
            //         cand_id:'Cand59324-Rohan Pawar-pawarrohan030@gmail.com',
            //         status_id: 1,
            //         user_id: 1
            //     }
            // );

            if (response && response.data && response.status === 200) {
                dispatch(slice.actions.setStatus(response.data.message));
            } else {
                dispatch(slice.actions.startError('Failed to set candidate state'));
            }
        } catch (error) {
            dispatch(slice.actions.startError('An error occurred while setting the status'));
        }
    };
}
