// third-party
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';
import engagmentFlow from './get_engagementFlows.json';

// ----------------------------------------------------------------------

const initialState = {
    engagementFlows: [],
    selectedFlow: null,
    error: null
};

const slice = createSlice({
    name: 'engagementFlows',
    initialState,
    reducers: {
        setEngagementFlows(state, action) {
            state.engagementFlows = action.payload;
        },
        setSelectedFlow(state, action) {
            state.selectedFlow = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Export actions
export const { setEngagementFlows, setSelectedFlow, setError } = slice.actions;

// Thunks
export function getEngagementFlows(job_id, client_id) {
    return async () => {
        try {
            // const formData = new FormData();
            // formData.append('job_id', job_id);
            // formData.append('client_id', client_id);

            //const response = await axios.get('http://35.200.233.124:5015/getEngagementFlows', formData);
            //const response = await axios.post('/getEngagementFlows', formData);

            const response = await axios.get('http://35.200.233.124:5015/getEngagementFlows',{
                params:{
                    job_id: job_id,
                    client_id: client_id
                }
            });

            // const response = await axios.post('http://35.200.233.124:5015/getEngagementFlows',
            //     {
            //         job_id:formData.get('job_id'),
            //         client_id:formData.get('client_id')
            //     }
            // );

            //const response = await axios.post('/getEngagementFlows', formData);
            //const response ={data:engagmentFlow, status:200};
            if (response && response.data && response.status === 200) {
                dispatch(slice.actions.setEngagementFlows(response.data));
            } else {
                dispatch(slice.actions.setError('Failed to fetch engagement flows'));
            }
        } catch (error) {
            console.log('getEngagementFlows error', error);
            dispatch(slice.actions.setError('An error occurred while fetching engagement flows'));
        }
    };
}
