// third-party
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    message: null,
    error: null
};

const slice = createSlice({
    name: 'stopEngagement',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.message = null;
            state.error = null;
        },
        stopSuccess(state, action) {
            state.loading = false;
            state.message = action.payload;
        },
        stopError(state, action) {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Export actions
export const { startLoading, stopSuccess, stopError } = slice.actions;

// Thunks
export function stopEngagement(candidate_id, job_id, engmt_flow_id) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());

            const formData = new FormData();
            formData.append('candidate_id', candidate_id);
            formData.append('job_id', job_id);
            formData.append('flow_id', engmt_flow_id);

            const response = await axios.post('http://35.200.233.124:5015/stopEngagementFlow', formData);

            if (response && response.data && response.status === 200) {
                dispatch(slice.actions.stopSuccess(response.data.message));
            } else {
                dispatch(slice.actions.stopError('Failed to stop engagement flow'));
            }
        } catch (error) {
            console.error('stopEngagementFlow error', error);
            dispatch(slice.actions.stopError('An error occurred while stopping the engagement flow'));
        }
    };
}
