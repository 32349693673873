// third-party
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    message: null,
    error: null
};

const slice = createSlice({
    name: 'logOfflineEngagement',
    initialState,
    reducers: {
        outcomeSuccess(state, action) {
            state.message = action.payload;
            //state.success = true;
            //state.error= null;
        },
        outcomeError(state, action) {
            //state.message = null;
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Export actions
export const { outcomeSuccess, outcomeError } = slice.actions;

// Thunks
export function logOfflineEngagement({ job_id, cand_id, flow_id, stage, channel, engagement_time, outcome, comment }) {
    return async () => {
        try {
            // Creating a FormData object to send data
            const formData = new FormData();
            formData.append('job_id', job_id);
            formData.append('cand_id', cand_id);
            formData.append('flow_id', flow_id);
            formData.append('stage', stage);
            formData.append('channel', channel);
            formData.append('engagement_time', engagement_time);
            formData.append('outcome', outcome);
            formData.append('comment', comment);

            // Sending the POST request to the server
            const response = await axios.post('http://35.200.233.124:5015/logOfflineEngagement', formData);
            if (response && response.data && response.status === 200) {
                dispatch(slice.actions.outcomeSuccess(response.data.message)); // Pass the success message from response
            } else {
                dispatch(slice.actions.outcomeError('Failed to log offline engagement'));
            }

            // If the request was successful
            //dispatch(outcomeSuccess());
        } catch (error) {
            console.error('sendEngagementOutcome error', error);
            dispatch(slice.actions.outcomeError('An error occurred while sending the engagement outcome'));
        }
    };
}
