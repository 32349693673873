// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';
import listPageTemplateOne from './slices/list-page-template-one';
import jobsReducer from './slices/jobs';
import jobDescriptionReducer from './slices/job-description';
import candidatesReducer from './slices/candidates';
import engagementFlowsReducer from './slices/engagementFlows';
import engagementFlowStepsReducer from './slices/engagementFlowSteps';
import startEngagementReducer from './slices/startEngagement';
import stopEngagementReducer from './slices/stopEngagement';
import candidateStatusReducer from './slices/candidateStatus';
import setCandidateStatusReducer from './slices/setCandidateStatus';
import logOfflineEngagementReducer from './slices/logOfflineEngagement';
import getAllCandidateDetailsReducer from './slices/getAllCandidateDetails';
import updateCandDetailsReducer from './slices/updateCandDetails';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contact: contactReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    menu: menuReducer,
    listPageTemplateOne: listPageTemplateOne,
    jobs: jobsReducer,
    jobDescription: jobDescriptionReducer,
    candidates: candidatesReducer,
    engagementFlows:engagementFlowsReducer,
    engagementFlowSteps:engagementFlowStepsReducer,
    startEngagement:startEngagementReducer,
    stopEngagement:stopEngagementReducer,
    candidateStatus: candidateStatusReducer,
    setCandidateStatus: setCandidateStatusReducer,
    logOfflineEngagement: logOfflineEngagementReducer,
    getAllCandidateDetails: getAllCandidateDetailsReducer,
    updateCandDetails: updateCandDetailsReducer,
});

export default reducer;
