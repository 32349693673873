// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';


const initialState = {
    columns: null,
    data: []
};

// ==============================|| SLICE - MENU ||============================== //

const listPageTemplateOne = createSlice({
    name: 'listPageTemplateOne',
    initialState,
    reducers: {
        getSetcolumns(state, action) {
            state.columns = action.payload;
        },

        getSetData(state, action) {
            state.data = action.payload;
        }
    }
});

export default listPageTemplateOne.reducer;

export const { getSetcolumns, getSetData } = listPageTemplateOne.actions;
